import ApiService from "../../core/services/api.service";

const apiService = ApiService;

export default class AdminUserService {
  #api = null;
  #apiStaff = null;

  constructor() {
    this.#api = `admin/admin-user`;
    this.#apiStaff = `admin/staff`;
  }

  requestMfaAuthenticator() {
    let url = `${this.#api}/get/mfa-authenticator`;
    return apiService.get(url);
  }

  activateMfaAuthenticator(data) {
    let url = `${this.#api}/activate/mfa-authenticator`;
    return apiService.post(url, data);
  }

  deActivateMfaAuthenticator() {
    let url = `${this.#api}/deactivate/mfa-authenticator`;
    return apiService.post(url);
  }

  resetMfaAuthenticator(id, $data) {
    let url = `${this.#api}/reset/mfa-authenticator/${id}`;
    return apiService.post(url, $data);
  }

  activateEmailVerificationCode() {
    let url = `${this.#api}/activate/email-authenticator`;
    return apiService.post(url);
  }

  deActivateEmailVerificationCode() {
    let url = `${this.#api}/deactivate/email-authenticator`;
    return apiService.post(url);
  }

  resetEmailAuthenticator(id) {
    let url = `${this.#api}/reset/email-authenticator/${id}`;
    return apiService.post(url);
  }

  requestPasswordReset(data) {
    let url = `${this.#api}/admin/do-reset/password`;
    return apiService.post(url, data);
  }

  all() {
    let url = `${this.#api}/get/all`;
    return apiService.get(url);
  }

  getAdminUser() {
    let url = `${this.#api}`;
    return apiService.get(url);
  }

  store(data) {
    let url = `${this.#api}`;
    return apiService.post(url, data);
  }

  show(id) {
    let url = `${this.#api}/${id}`;
    return apiService.get(url);
  }

  update(id, data) {
    let url = `${this.#api}/${id}`;
    return apiService.post(url, data);
  }

  delete(id) {
    let url = `${this.#api}/${id}`;
    return apiService.delete(url);
  }

  getStaff() {
    let url = `${this.#apiStaff}`;
    return apiService.get(url);
  }

  updateUserType(id, data) {
    let url = `${this.#api}/${id}/update/user-type`;
    return apiService.post(url, data);
  }

  assignRole(id, data) {
    let url = `${this.#api}/${id}/assign-role`;
    return apiService.post(url, data);
  }

  removeRole(id, data) {
    let url = `${this.#api}/${id}/remove-role`;
    return apiService.post(url, data);
  }

  userRoles(id) {
    let url = `${this.#api}/${id}/roles`;
    return apiService.get(url);
  }

  assignAgent(userId, data = {}) {
    let url = `${this.#api}/${userId}/assign-agent`;
    return apiService.post(url, data);
  }
}
