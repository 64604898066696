<template>
  <div>
    <!--begin::Card-->
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                v-if="user.image_path"
                :src="user.image_path.original"
                alt="image"
              />
            </div>
            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span class="font-size-h3 symbol-label font-weight-boldest"
                >JM</span
              >
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="#"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >{{ user.name }}</a
                >
                <a href="#"
                  ><i class="flaticon2-correct text-success font-size-h5"></i
                ></a>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                    >{{ user.email }}</a
                  >
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><i class="flaticon2-calendar-3 mr-2 font-size-lg"></i
                    >{{ user.user_type }}
                  </a>
                  <a
                    href="#"
                    class="text-dark-50 text-hover-primary font-weight-bold"
                    ><i class="flaticon2-placeholder mr-2 font-size-lg"></i
                    >{{ user.address_line_1 }}</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >I distinguish three main text objectives could be merely to
                  inform people.</span
                >
                <span class="font-weight-bold text-dark-50"
                  >A second could be persuade people.You want people to bay
                  objective</span
                >
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>
      </div>
    </div>
    <!--end::Card-->

    <div>
      <!--begin::Card-->
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <!--begin::Details-->
          <div class="d-flex mb-9">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">
                      <div class="row">
                        <div class="col-12">
                          <h4>User Password and Security Setting</h4>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <v-col cols="12" md="4" class="card p-10 mt-10">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-8">
                              <h3>Setup MFA Authenticator</h3>
                              <p>
                                Use multi-factor authentication (MFA) to add an
                                extra layer of security to your account.
                              </p>
                            </div>
                            <div class="col-md-4 text-right">
                              <p>
                                <img
                                  src="media/myprofile/authenticator.webp"
                                  style="max-width: 64px"
                                />
                              </p>
                            </div>
                            <v-btn
                              v-if="!user.is_mfa_enabled"
                              color="white--text v-btn theme--light elevation-2 v-size--large primary"
                              class="btn-save-popup"
                              text
                              :loading="isMfaAuthLoading"
                              :disabled="isUserId"
                              @click="getMfaAuthenticatorCode"
                              >Setup MFA Authenticator</v-btn
                            >
                            <v-btn
                              v-else
                              color="white"
                              class="bg-danger"
                              text
                              :loading="isMfaAuthLoading"
                              @click="resetOrDeactivateMfaAuthenticator"
                              >Deactivate MFA Authenticator</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" class="card p-10 mt-10">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-8">
                              <h3>Setup MFA - Email Verification</h3>
                              <p>
                                Use multi-factor authentication (MFA) to add an
                                extra layer of security to your account.
                              </p>
                            </div>
                            <div class="col-md-4 text-right">
                              <p>
                                <img
                                  src="media/myprofile/important-mail.png"
                                  style="max-width: 64px"
                                />
                              </p>
                            </div>
                            <v-btn
                              color="white--text v-btn theme--light elevation-2 v-size--large primary"
                              class="btn-save-popup"
                              text
                              v-if="!user.is_email_authentication_enabled"
                              @click="getEmailAuthenticator"
                              :disabled="isUserId"
                              >Setup Email Authenticator</v-btn
                            >

                            <v-btn
                              color="white"
                              class="bg-danger"
                              text
                              v-else
                              @click="resetOrDeactivateEmailAuthenticator"
                              >Deactivate Email Authenticator</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" class="card p-10 mt-10">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-8">
                              <h3>Password</h3>
                              <p>
                                Setup secure password to access and manage your
                                account.
                              </p>
                            </div>
                            <div class="col-md-4 text-right">
                              <p>
                                <img
                                  src="media/myprofile/password.png"
                                  style="max-width: 64px"
                                />
                              </p>
                            </div>
                            <v-btn
                              color="white--text v-btn theme--light elevation-2 v-size--large primary"
                              class="btn-save-popup"
                              text
                              @click="showChangePasswordDialog"
                              >Change Password</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                    </div>
                  </div>
                </div>
              </div>

              <!-- MFA Authenticator Setup Modal -->
              <v-dialog
                v-model="mfaAuthenticatorSetup"
                persistent
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Setup MFA Authenticator</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <div class="form-block">
                        <v-row>
                          <v-col cols="12">
                            <ul class="mfa-instructions">
                              <li>
                                Get the MFA Authenticator App from the Apple
                                AppStore or Google PlayStore
                              </li>
                              <li>In the app, select account setup</li>
                              <li>
                                Choose to Scan Barcode, then scan code below
                              </li>
                            </ul>
                          </v-col>

                          <v-col cols="12" class="text-center">
                            <v-row align="center" justify="center">
                              <v-img
                                :src="authenticator.mfa.image_url"
                                :lazy-src="
                                  `https://picsum.photos/200/200?random`
                                "
                                max-width="200"
                                max-height="200"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-row>
                          </v-col>
                          <v-col cols="12" class="text-center">
                            <a
                              class="link-title"
                              @click="mfaSecret = !mfaSecret"
                              >or Add Your Key Manually</a
                            >
                            <v-row class="mfa-secret-key" v-if="mfaSecret">
                              <v-col cols="12">
                                <p>
                                  <b>Account:</b>
                                  {{ authenticator.mfa.account }}
                                </p>
                                <p>
                                  <b>Secret Key:</b>
                                  {{ authenticator.mfa.secret_key }}
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <v-text-field
                              label="Enter Verification Code Displayed"
                              v-model="authenticator.mfa.verification_code"
                              required
                              dense
                              outlined
                            ></v-text-field>
                            <p
                              class="text-danger font-weight-bold"
                              v-if="
                                $v.authenticator.mfa.verification_code.$error
                              "
                            >
                              Please Enter a Verification Code
                            </p>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="closeMfaAuthenticatorSetup"
                      >Close</v-btn
                    >
                    <v-btn
                      color="white--text v-btn theme--light elevation-2 v-size--large primary"
                      class="btn-save-popup"
                      text
                      @click.prevent="activateMfaAuthenticator"
                      >Save & Activate</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- MFA Authenticator Setup Modal -->

              <!-- Email Authenticator Setup Modal -->
              <v-dialog
                v-model="emailAuthenticationSetup"
                persistent
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Setup Email Authenticator</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <div class="form-block">
                        <v-row>
                          <v-col cols="12">
                            <ul class="mfa-instructions">
                              <li>
                                Setup your email and add an extra layer of
                                security to your account.
                              </li>
                            </ul>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      :loading="loading"
                      text
                      @click="closeEmailAuthenticatorDialog"
                      >Close</v-btn
                    >
                    <v-btn
                      color="white--text v-btn theme--light elevation-2 v-size--large primary"
                      class="btn-save-popup"
                      text
                      :loading="isEmailAuthLoading"
                      @click.once="activateEmailAuthenticator"
                      >Activate</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Email Authenticator Setup Modal -->

              <!-- Change Password Modal -->
              <v-dialog
                v-model="changePasswordDialog"
                persistent
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="headline">Change Password</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <div class="form-block">
                        <v-row>
                          <v-col cols="12">
                            <ul class="mfa-instructions">
                              <li>
                                You will receive an email to reset your
                                password.
                              </li>
                            </ul>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      :loading="loading"
                      text
                      @click="closeChangePasswordDialog"
                      >Close</v-btn
                    >
                    <v-btn
                      color="white--text v-btn theme--light elevation-2 v-size--large primary"
                      class="btn-save-popup"
                      text
                      @click="sendPasswordResetEmail"
                      :loading="isChangePasswordLoading"
                      :disabled="emailVerificationMailRequested"
                      >{{
                        emailVerificationMailRequested
                          ? "Please wait for a minute"
                          : "Request Email"
                      }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Change Password Modal -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Row-->
    <div class="row">
      <div class="col-lg-12"></div>
    </div>
    <!--end::Row-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { minLength, sameAs, required } from "vuelidate/lib/validators";
import AdminUserService from "@/service/user/AdminUserService";

const adminUserService = new AdminUserService();

export default {
  name: "MyProfile",
  mixins: [validationMixin],
  data() {
    return {
      userId: null,
      isUserId: false,
      showMobileInput: true,
      isEmailAuthLoading: false,
      isChangePasswordLoading: false,
      emailVerificationMailRequested: false,
      isBusy: false,
      mobileRecoverySetup: false,
      mobileNo: null,
      mfaAuthenticatorSetup: false,
      emailAuthenticationSetup: false,
      changePasswordDialog: false,
      mfaSecret: false,
      loading: false,
      page: false,
      isMfaAuthLoading: false,
      authenticator: {
        mfa: {
          verification_code: "",
          image_url: "",
          account: "",
          secret_key: ""
        }
      },
      user: {}
    };
  },
  validations: {
    user: {
      password: { required, minLength: minLength(8) },
      confirm_password: { required, sameAsPassword: sameAs("password") }
    },
    authenticator: {
      mfa: {
        verification_code: { required }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "My Profile" }]);
    this.userId = this.$route.params.id;
    if (this.userId !== null && this.userId !== undefined) {
      this.isUserId = true;
      this.getAdminUser();
    } else {
      this.isUserId = false;
      this.user = this.$store.getters.currentUser;
    }
  },
  methods: {
    getMfaAuthenticatorCode() {
      this.isMfaAuthLoading = true;
      adminUserService.requestMfaAuthenticator().then(res => {
        this.authenticator.mfa.account = res.data.account;
        this.authenticator.mfa.secret_key = res.data.secret_key;
        this.authenticator.mfa.image_url = res.data.image_url;
        this.mfaAuthenticatorSetup = true;
        this.isMfaAuthLoading = false;
      });
    },

    resetOrDeactivateMfaAuthenticator() {
      this.isMfaAuthLoading = true;
      if (this.isUserId) {
        this.resetMfaAuthenticator();
      } else {
        this.deActivateMfaAuthenticator();
      }
    },

    resetOrDeactivateEmailAuthenticator() {
      this.isEmailAuthLoading = true;
      this.emailAuthenticationSetup = false;
      this.user.is_email_authentication_enabled = false;
      if (this.isUserId) {
        this.resetEmailAuthenticator();
      } else {
        this.deactivateEmailAuthenticator();
      }
    },

    closeMfaAuthenticatorSetup() {
      this.mfaAuthenticatorSetup = false;
      this.authenticator.mfa.account = "";
      this.authenticator.mfa.secret_key = "";
      this.authenticator.mfa.image_url = "";
    },

    activateMfaAuthenticator() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.authenticator.mfa.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        adminUserService
          .activateMfaAuthenticator(this.authenticator.mfa)
          .then(res => {
            this.authenticator.mfa = {
              verification_code: "",
              image_url: "",
              account: "",
              secret_key: ""
            };
            this.isMfaAuthLoading = false;
            this.loading = false;
            if (res.data.status == "OK") {
              this.mfaAuthenticatorSetup = false;
              this.$snotify.success("MFA Activated");
              this.getAdminUser();
            } else this.$snotify.error("Problem occurred");
          });
      }
    },

    deActivateMfaAuthenticator() {
      adminUserService.deActivateMfaAuthenticator().then(res => {
        this.isMfaAuthLoading = false;
        if (res.data.status == "OK") {
          this.user.is_mfa_enabled = false;
          this.$snotify.success("MFA Deactivated");
          this.getAdminUser();
        }
      });
    },

    resetMfaAuthenticator() {
      adminUserService.resetMfaAuthenticator(this.userId).then(res => {
        this.isMfaAuthLoading = false;
        if (res.data.status == "OK") {
          this.user.is_mfa_enabled = false;
          this.$snotify.success("MFA Deactivated");
          this.getAdminUser();
        }
      });
    },

    activateEmailAuthenticator() {
      this.isEmailAuthLoading = true;
      this.emailAuthenticationSetup = false;
      this.user.is_email_authentication_enabled = true;
      adminUserService.activateEmailVerificationCode().then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("Email Authenticator Activated");
          this.isEmailAuthLoading = false;
          this.emailAuthenticationSetup = false;
          this.getAdminUser();
        }
      });
    },

    deactivateEmailAuthenticator() {
      this.isEmailAuthLoading = true;
      this.emailAuthenticationSetup = false;
      this.user.is_email_authentication_enabled = false;
      adminUserService.deActivateEmailVerificationCode().then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("Email Authenticator Deactivated");
          this.isEmailAuthLoading = false;
          this.emailAuthenticationSetup = false;
          this.user.is_email_authentication_enabled = false;
          this.getAdminUser();
        }
      });
    },

    resetEmailAuthenticator() {
      this.isEmailAuthLoading = true;
      this.emailAuthenticationSetup = false;
      this.user.is_email_authentication_enabled = false;
      adminUserService.resetEmailAuthenticator(this.userId).then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("Email Authenticator Deactivated");
          this.isEmailAuthLoading = false;
          this.emailAuthenticationSetup = false;
          this.user.is_email_authentication_enabled = false;
          this.getAdminUser();
        }
      });
    },

    getEmailAuthenticator() {
      this.emailAuthenticationSetup = true;
    },

    closeEmailAuthenticatorDialog() {
      this.emailAuthenticationSetup = false;
    },

    closeChangePasswordDialog() {
      this.changePasswordDialog = false;
    },

    showChangePasswordDialog() {
      this.changePasswordDialog = true;
    },

    saveUser() {
      this.$v.user.$touch();
      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      } else {
        this.update();
      }
    },
    updateUser() {
      adminUserService.update(this.user.id, this.user).then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("updated successfully");
          this.getAdminUser();
        }
      });
    },
    sendPasswordResetEmail() {
      adminUserService
        .requestPasswordReset({ email: this.user.email })
        .then(response => {
          this.closeChangePasswordDialog();
          this.$snotify.success(response.data.msg);
        });
    },
    getAdminUser() {
      if (this.isUserId) {
        adminUserService.show(this.userId).then(response => {
          this.user = response.data.adminUser;
        });
      } else {
        adminUserService.show(this.user.id).then(response => {
          this.user = response.data.adminUser;
        });
      }
    }
  }
};
</script>
