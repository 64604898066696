<template>
  <v-app>
    <transition name="fade-in-up">
      <my-profile></my-profile>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MyProfile from "@/view/pages/myprofile/MyProfile";
export default {
  name: "Profile",
  components: {
    MyProfile
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Admin Profile" }]);
  },
  methods: {}
};
</script>
